define("discourse/plugins/discourse-track-open-topic-counts/discourse/components/discourse-track-open-topic-counts/category-custom-settings", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>{{i18n "track_open_topic_counts.edit_category.header"}}</h3>
  
  <section class="field track-open-topic-counts">
    <label>
      {{i18n
        "track_open_topic_counts.edit_category.display_open_topics_count_sidebar.label"
      }}
    </label>
  
    <div class="controls">
      <ComboBox
        @valueProperty="value"
        @content={{this.sidebarDisplayOpenTopicsCountOptions}}
        @value={{@category.custom_fields.display_open_topic_count_sidebar}}
        @onChange={{this.onChangeSidebarDisplayOpenTopicsCountOption}}
        @options={{hash
          translatedNone=this.sidebarDisplayOpenTopicsCountNoneLabel
          placementStrategy="absolute"
        }}
        class="sidebar-display-open-topics-count"
      />
    </div>
  </section>
  */
  {
    "id": "sUSgcYvc",
    "block": "[[[10,\"h3\"],[12],[1,[28,[35,1],[\"track_open_topic_counts.edit_category.header\"],null]],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"field track-open-topic-counts\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"track_open_topic_counts.edit_category.display_open_topics_count_sidebar.label\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,5],[[24,0,\"sidebar-display-open-topics-count\"]],[[\"@valueProperty\",\"@content\",\"@value\",\"@onChange\",\"@options\"],[\"value\",[30,0,[\"sidebarDisplayOpenTopicsCountOptions\"]],[30,1,[\"custom_fields\",\"display_open_topic_count_sidebar\"]],[30,0,[\"onChangeSidebarDisplayOpenTopicsCountOption\"]],[28,[37,6],null,[[\"translatedNone\",\"placementStrategy\"],[[30,0,[\"sidebarDisplayOpenTopicsCountNoneLabel\"]],\"absolute\"]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@category\"],false,[\"h3\",\"i18n\",\"section\",\"label\",\"div\",\"combo-box\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-track-open-topic-counts/discourse/components/discourse-track-open-topic-counts/category-custom-settings.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component2.default {
    get sidebarDisplayOpenTopicsCountOptions() {
      return [{
        value: "always",
        name: (0, _discourseI18n.i18n)("track_open_topic_counts.edit_category.display_open_topics_count_sidebar.always")
      }, {
        value: "no_unread_new",
        name: (0, _discourseI18n.i18n)("track_open_topic_counts.edit_category.display_open_topics_count_sidebar.no_unread_new")
      }];
    }
    get sidebarDisplayOpenTopicsCountNoneLabel() {
      return (0, _discourseI18n.i18n)("track_open_topic_counts.edit_category.display_open_topics_count_sidebar.never");
    }
    onChangeSidebarDisplayOpenTopicsCountOption(value) {
      this.args.category.set("custom_fields.display_open_topic_count_sidebar", value);
    }
    static #_ = (() => dt7948.n(this.prototype, "onChangeSidebarDisplayOpenTopicsCountOption", [_object.action]))();
  });
});